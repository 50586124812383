import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Hidden from '@material-ui/core/Hidden';
import { useSelector } from 'react-redux';
import { ExperiencesContextProvider } from '../../../../../contexts/ExperiencesContext';
import { userAppSelector } from '../../../../../redux/reducers/User';
import AppContext from '../../../contextProvider/AppContextProvider/AppContext';
import CmtVerticalLayout from '../../../../../@coremat/CmtLayouts/Vertical';
import CmtSidebar from '../../../../../@coremat/CmtLayouts/Vertical/Sidebar';
import SideBar from '../../partials/SideBar';
import CmtContent from '../../../../../@coremat/CmtLayouts/Vertical/Content';
import ContentLoader from '../../../ContentLoader';
import ActionSideBar from './ActionSideBar';
import Logo from '../../partials/Logo';
import { SIDEBAR_TYPE } from '../../../../constants/ThemeOptions';

const useStyles = makeStyles(theme => ({
  sidebarHeader: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 20px',
      height: 72,
    },
  },
}));

// eslint-disable-next-line react/prop-types
const ModernSideBar = ({ children, className, modules }) => {
  const classes = useStyles();
  const { drawerBreakPoint, sidebarSize, sidebarStyle } = useContext(
    AppContext,
  );
  const [sidebarWidth, setSidebarWidth] = useState(sidebarSize);
  // FIX THIS - should not need AppId pass the list of experiences
  const appInfo = useSelector(userAppSelector);
  const appId = appInfo.app;

  return (
    <CmtVerticalLayout
      drawerBreakPoint={drawerBreakPoint}
      className={clsx('Cmt-modernLayout', className)}
      sidebarWidth={sidebarWidth > 0 ? sidebarSize : sidebarWidth}>
      <CmtSidebar
        type={SIDEBAR_TYPE.FULL}
        isSidebarFixed
        actionBar={<ActionSideBar setSidebarWidth={setSidebarWidth} />}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...sidebarStyle}>
        <Hidden mdDown>
          <Logo className={classes.sidebarHeader} />
        </Hidden>
        <ExperiencesContextProvider appId={appId}>
          <SideBar modules={modules} />
        </ExperiencesContextProvider>
      </CmtSidebar>
      <CmtContent>
        {children}
        <ContentLoader />
      </CmtContent>
    </CmtVerticalLayout>
  );
};

export default ModernSideBar;
