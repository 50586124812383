import React, { createContext, useContext } from 'react';

import { useExperiences } from '../hooks/useExperiences';

export const ExperiencesContext = createContext();
export const useExperiencesContext = () => useContext(ExperiencesContext);

// eslint-disable-next-line react/prop-types
export const ExperiencesContextProvider = ({ children, appId }) => {
  const { experiences } = useExperiences(appId);

  const value = {
    experiences,
  };

  return (
    <ExperiencesContext.Provider value={value}>
      {children}
    </ExperiencesContext.Provider>
  );
};
