/* eslint-disable */

import React, { useState, useRef, useEffect } from 'react';
import CmtCard from '../../@coremat/CmtCard';
import CmtCardHeader from '../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import RecentTable from './RecentTable';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  scrollbarRoot: {
    height: 345,
    '& .ps__rail-x': {
      opacity: 0.6,
    },
    '& .ps__rail-y': {
      opacity: 0.6,
    },
  },
  container: {
    maxHeight: 345,
  },
}));

export const FeatureTable = ({ data }) => {
  const classes = useStyles();
  let _scrollRef = useRef(null);

  useEffect(() => {
    if (_scrollRef) {
      _scrollRef.current.scrollTop = 8;
    }
  }, [_scrollRef]);
  return (
    <CmtCard classes={{ root: classes.root }}>
      <CmtCardHeader
        className="pt-4"
        title="Model Features and Importance"
        titleProps={{
          variant: 'h4',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent>
        <PerfectScrollbar
          containerRef={ref => {
            _scrollRef.current = ref;
          }}
          className={classes.scrollbarRoot}>
          <RecentTable tableData={data} />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};
