/* eslint-disable */

import React from 'react';

// Core
import StatisticsClassicCard from '../../@jumbo/components/Common/StatisticsClassicCard';

// Components
import { UniqueVisitorsWithScoreGraph } from './UniqueVisitorsWithScoreGraph';

// Hooks
// import { useTheme } from '@material-ui/core/styles';

export const UniqueVisitorsWithScore = () => {
  // const theme = useTheme();
  return (
    <StatisticsClassicCard
      backgroundColor={['#E2EEFF -18.96%', '#fff 108.17%']}
      gradientDirection="180deg"
      color={'#0062FF'}
      title={'10,254'}
      subTitle={'Unique Visitors with Score'}
      growth={1.5}>
      <UniqueVisitorsWithScoreGraph />
    </StatisticsClassicCard>
  );
};
