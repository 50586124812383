/* eslint-disable */

import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PeopleIcon from '@material-ui/icons/People';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CmtAvatar from '@coremat/CmtAvatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  scoreCol: {
    verticalAlign: 'top',
  },
});

const MARKETING_CHANNEL_ICON_MAP = {
  Chrome: { path: '/images/dashboard/chrome.png' },
  Safari: { path: '/images/dashboard/safari.png' },
  Firefox: { path: '/images/dashboard/firefox.png' },
  Edge: { path: '/images/dashboard/internet_explorer.png' },
  Opera: { path: '/images/dashboard/opera.png' },
  'Samsung Internet': { component: PeopleIcon },
  unknown: { component: PeopleIcon },
};

const getIcon = key => {
  const Comp = MARKETING_CHANNEL_ICON_MAP[key] || PeopleIcon;
  const { component: Component, color, path } = Comp;

  if (Component) {
    return (
      <CmtAvatar>
        <Component />
      </CmtAvatar>
    );
  } else {
    return <CmtAvatar size={46} src={path} style={{ backgroundColor: 'transparent' }} />;
  }
};

const MarketingChannelScoreTable = ({ tableData = [] }) => {
  const classes = useStyles();

  return (
    <Box className="Cmt-table-responsive">
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography>Browser</Typography>
              <Typography color="textSecondary" variant="caption">
                # of Users
              </Typography>
            </TableCell>
            <TableCell align="right" className={classes.scoreCol}>
              <Typography>Avg Score</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="left" style={{ display: 'flex', minWidth: '250px' }}>
                  <Grid container spacing={6} alignItems="center">
                    <Grid item>{getIcon(row.name)}</Grid>
                    <Grid item style={{ flex: 1, padding: 0 }}>
                      <Typography style={{ textOverflow: 'ellipsis' }}>{row.name}</Typography>
                      <Typography color="textSecondary" variant="caption">
                        {row.totalVisitors} Visitors
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="right">
                  <Typography>{Math.round(row.average)}</Typography>
                  <Typography color="textSecondary" variant="caption">
                    out of 100
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell style={{ display: 'flex', minWidth: '300px' }}>
                <Typography align="center">Not enough data</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MarketingChannelScoreTable;
