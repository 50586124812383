/* eslint-disable */

import { useEffect, useState } from 'react';

import { DataService } from '../services/data';

const CurrentDataService = 'azure';

export const useModelDataResource = (appId, model, service = CurrentDataService) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataService] = useState(DataService[service]);
  const [groupedUserScores, setGroupedUserScores] = useState({});
  const [featureImportance, setFeatureImportance] = useState([]);
  const [marketingChannels, setMarketingChannels] = useState([]);
  const [browsers, setBrowsers] = useState([]);
  const [devices, setDevices] = useState([]);
  const [modelAccuracy, setModelAccuracy] = useState(0);

  const fetch = async () => {
    if (!appId) return;

    setIsLoading(true);

    const modelData = await dataService.get(appId,`/${model}_test.json`);

    console.log(modelData);

    if (modelData) {
      const {
        featureImportance,
        groupedUserScores,
        marketingChannels,
        modelAccuracy,
        browsers: browsersScore,
        devices: devicesScore,
      } = modelData;
      if (featureImportance) setFeatureImportance(featureImportance);
      if (groupedUserScores) setGroupedUserScores(groupedUserScores);
      if (marketingChannels) setMarketingChannels(marketingChannels);
      if (browsers) setBrowsers(browsersScore);
      if (devices) setDevices(devicesScore);
      if (modelAccuracy) setModelAccuracy(modelAccuracy);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    async function _fetch() {
      fetch();
    }

    _fetch();
  }, [appId]);

  return {
    isLoading,
    groupedUserScores,
    featureImportance,
    fetch,
    marketingChannels,
    modelAccuracy,
    browsers,
    devices,
  };
};
