/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { PostAdd, Add, CameraFront } from '@material-ui/icons';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import IntlMessages from '../../../../utils/IntlMessages';
import { useExperiencesContext } from '../../../../../contexts/ExperiencesContext';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const ICONS = {
  POST_ADD: PostAdd,
  ADD: Add,
  EXPERIENCE: CameraFront,
};

const getIcon = key => {
  const Comp = ICONS[key];
  return <Comp />;
};

const SideBar = ({ modules }) => {
  const { experiences } = useExperiencesContext();
  const classes = useStyles();
  const appExperiences = experiences.map(x => {
    return {
      name: x.name,
      icon: getIcon('EXPERIENCE'),
      type: 'button',
      link: `/experiences/${x.id}`,
      classes,
    };
  });

  const navigationMenus = [
    {
      name: <IntlMessages id="sidebar.model" />,
      type: 'section',
      children: [
        {
          name: <IntlMessages id="pages.engagement" />,
          icon: getIcon('POST_ADD'),
          type: 'item',
          link: '/engagement',
        },
        {
          name: <IntlMessages id="pages.conversion" />,
          icon: getIcon('POST_ADD'),
          type: 'item',
          link: '/conversion',
        },
      ],
    },
    {
      name: <IntlMessages id="sidebar.experiences" />,
      type: 'section',
      children: [
        {
          name: 'New Experience',
          icon: getIcon('ADD'),
          type: 'button',
          link: '/experiences/new',
          classes,
        },
        ...appExperiences,
      ],
    },
  ];

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={navigationMenus} />
    </PerfectScrollbar>
  );
};

export default SideBar;
