/* eslint-disable */

import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade, IconButton, Tooltip, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    padding: 16,
    fontSize: 14,
    // letterSpacing: 0.25,
    color: theme.palette.text.secondary,
    // borderBottom: '0 none',
    position: 'relative',
    '&:first-child': {
      // paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      color: theme.palette.error.main,
      // paddingRight: 24,
    },
    '&.success': {
      color: theme.palette.success.main,
    },
    '& .Cmt-media-object': {
      alignItems: 'center',
    },
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,
    padding: '2px 10px',
    display: 'inline-block',
  },
}));

function getBgColor(status) {
  const color = {
    cancelled: '#E00930',
    completed: '#0795F4',
    delayed: '#03DAC5',
    onHold: '#FF8C00',
  };
  return color[status];
}

const TableItem = ({ row }) => {
  const classes = useStyles();

  return (
    <TableRow>
      <TableCell align="left">
        <Grid conatiner>
          <Typography color="textSecondary">{row.name}</Typography>
        </Grid>
      </TableCell>
      <TableCell align="right">
        <Grid conatiner>
          <Typography color="textSecondary">{Math.round(row.importance)}%</Typography>
        </Grid>
      </TableCell>

      {/* <TableCell>{Math.round(row.importance)}%</TableCell> */}
    </TableRow>
  );
};

export default TableItem;
