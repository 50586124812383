/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Home } from './Pages/Home/Home';
import CoversionPage from './Pages/CoversionPage';
import SamplePage from './Pages/SamplePage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Modules from './Modules';

import { useKeycloak } from '@react-keycloak/web';
import { CircularProgress, Grid } from '@material-ui/core';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { keycloak, initialized } = useKeycloak();

  return (
    <Route
      {...rest}
      render={props =>
        keycloak.authenticated ? (
          <Component {...props} />
        ) : (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )
      }
    />
  );
};

const Routes = () => {
  const location = useLocation();
  const { keycloak } = useKeycloak();

  if (keycloak.authenticated && location.pathname === '/signin') {
    return <Redirect to={'/'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/" exact component={Home} />
        <RestrictedRoute path="/engagement" exact component={Home} />
        <RestrictedRoute path="/conversion" exact component={CoversionPage} />
        <RestrictedRoute path="/sample-page" component={SamplePage} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <RestrictedRoute
          path="/experiences"
          component={Modules}></RestrictedRoute>
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
