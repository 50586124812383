/* eslint-disable */

import React from 'react';

// Core
import GridContainer from '../@jumbo/components/GridContainer';
import { Grid, Box } from '@material-ui/core';
import PageContainer from '../@jumbo/components/PageComponents/layouts/PageContainer';

// Components
import { ColdVsHotUsers } from './EngagementModel/ColdVsHotUsers';
import { FeatureTable } from './common/FeatureTable';
import { ModelAccuracy } from './EngagementModel/ModelAccuracy';
import { ModelVsAction } from './EngagementModel/ModelVsAction';
import { UniqueVisitorsWithScore } from './EngagementModel/UniqueVisitorsWithScore';

// Context
import { useEngagementModel } from '../contexts/EngagementModalContext';
import MarketingChannelScoreCard from './common/MarketingChannelScoreCard';
import UserBrowserScoreCard from './common/UserBrowserScoreCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/styles';
import DeviceScoreCard from './common/DeviceScoreCard';

const breadcrumbs = [{ label: 'Dashboard', isActive: true }];

export const Dashboard = () => {
  const {
    isLoading,
    aggregatedGroupedData,
    aggregatedSummedData,
    featureImportance,
    modelAccuracy,
    marketingChannels,
    browsers,
    devices,
  } = useEngagementModel();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const { data: groupedData, yAxisKeys: groupedYAxisKeys, xAxisKey: groupedXAxisKey } = aggregatedGroupedData;
  const { data: summedData, yAxisKeys: summedYAxisKeys, xAxisKey: summedXAxisKey } = aggregatedSummedData;
  const hasSummedData = Object.keys(summedData).length > 0;

  if (isLoading) return <h1>LOADING...</h1>;

  return (
    <PageContainer heading="Propensely Algorithm Overview" breadcrumbs={[]}>
      <GridContainer>
        <Grid item xs={12} container>
          <Grid container item xs={12} md={4}>
            <Grid item xs={12}>
              <UniqueVisitorsWithScore />
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 16 }}>
              <ModelAccuracy modelAccuracy={modelAccuracy} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={8}
            style={{
              paddingLeft: isMobile ? 0 : 16,
              paddingTop: isMobile ? 16 : 0,
            }}>
            <Grid item xs={12}>
              <FeatureTable data={featureImportance} />
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} container item>
          <Grid container item xs={12} md={4}>
            <Grid item xs={12}>
              <MarketingChannelScoreCard data={marketingChannels} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            style={{
              paddingTop: isMobile ? 16 : 0,
              paddingLeft: isMobile ? 0 : 16,
            }}>
            <Grid item xs={12}>
              <UserBrowserScoreCard data={browsers} />
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={4}
            style={{
              paddingTop: isMobile ? 16 : 0,
              paddingLeft: isMobile ? 0 : 16,
            }}>
            <Grid item xs={12}>
              <DeviceScoreCard data={devices} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ModelVsAction data={groupedData} yAxisKeys={groupedYAxisKeys} xAxisKey={groupedXAxisKey} />
        </Grid>
        {hasSummedData && (
          <Grid item xs={12}>
            <ColdVsHotUsers data={summedData} yAxisKeys={summedYAxisKeys} xAxisKey={summedXAxisKey} />
          </Grid>
        )}
      </GridContainer>
    </PageContainer>
  );
};
