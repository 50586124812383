import React from 'react';

// Core
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

export const ColdVsHotUsersBarGraph = ({ data, yAxisKeys, xAxisKey }) => {
  return (
    <ResponsiveContainer height={500} width="100%">
      <BarChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis orientation="left" />
        <Tooltip labelStyle={{ color: 'black' }} labelFormatter={value => `Score Bucket: ${value}`} cursor={false} />
        {yAxisKeys.map((key, i) => (
          <Bar key={key} dataKey={key} fill="blue" />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
