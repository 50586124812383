/* eslint-disable */

import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { Typography, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const RecentTable = ({ tableData }) => {
  return (
    <Table style={{ tableLayout: 'fixed' }}>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            <Typography>Feature Name</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography>Importance</Typography>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {tableData.map((row, index) => (
          <TableRow key={index}>
            <TableCell align="left" style={{ display: 'flex', minWidth: '250px' }}>
              <Grid>
                <Grid item>
                  <Typography style={{ overflowWrap: 'anywhere' }} color="textSecondary">
                    {row.name}
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="right">
              <Grid>
                <Grid item>
                  <Typography color="textSecondary">{Math.round(row.importance)}%</Typography>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RecentTable;
