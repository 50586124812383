import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { userAppSelector } from '../../../redux/reducers/User';

// Components
import { Dashboard } from '../../../components/Dashboard';

// Context
import { EngagementModelProvider } from '../../../contexts/EngagementModalContext';

// eslint-disable-next-line import/prefer-default-export
export const Home = memo(() => {
  const appInfo = useSelector(userAppSelector);
  const appId = appInfo.app;
  return (
    <EngagementModelProvider appId={appId}>
      <Dashboard />
    </EngagementModelProvider>
  );
});
