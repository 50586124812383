/* eslint-disable */
import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import TableItem from './TableItem';
import Box from '@material-ui/core/Box';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import EmailIcon from '@material-ui/icons/Email';
import TwitterIcon from '@material-ui/icons/Twitter';
import PeopleIcon from '@material-ui/icons/People';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ShopIcon from '@material-ui/icons/Shop';
import CmtAvatar from '@coremat/CmtAvatar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  scoreCol: {
    verticalAlign: 'top',
  },
});

const MARKETING_CHANNEL_ICON_MAP = {
  'Direct/Other': { component: PeopleIcon, color: '#6200EE' },
  Facebook: { component: FacebookIcon, color: '#4D4BCC' },
  Google: { path: '/images/dashboard/icon-google-ad.png' },
  Amazon: { component: ShopIcon },
  Instagram: { component: InstagramIcon, color: '#CC4BB7' },
  YouTube: { component: YouTubeIcon, color: '#ff0000' },
  NewsLetter: { component: EmailIcon, color: '#FFE16E' },
  Twitter: { component: TwitterIcon, color: '#17A9FC' },
};

const getIcon = key => {
  const Comp = MARKETING_CHANNEL_ICON_MAP[key] || PeopleIcon;
  const { component: Component, color, path } = Comp;
  if (Component) {
    return (
      <CmtAvatar style={{ backgroundColor: color }}>
        <Component />
      </CmtAvatar>
    );
  } else {
    return <CmtAvatar size={56} src={path} />;
  }
};

const MarketingChannelScoreTable = ({ tableData }) => {
  const classes = useStyles();
  return (
    <Box className="Cmt-table-responsive">
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left" align="left" style={{ display: 'flex', minWidth: '350px' }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>Acquisition Channel</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textSecondary" variant="caption">
                    # of Users
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell align="right" className={classes.scoreCol}>
              <Typography>Avg Score</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              <TableCell align="left" style={{ display: 'flex', minWidth: '250px' }}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item>{getIcon(row.name)}</Grid>
                  <Grid>
                    <Typography>{row.name}</Typography>
                    <Typography color="textSecondary" variant="caption">
                      {row.totalVisitors} Visitors
                    </Typography>
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="right">
                <Typography>{Math.round(row.average)}</Typography>
                <Typography color="textSecondary" variant="caption">
                  out of 100
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default MarketingChannelScoreTable;
