/* eslint-disable no-console */
import uniqueId from 'lodash/uniqueId';
import client from '../../utils/azure/azureBlobClient';

const generateDefaultConfig = () => ({
  headers: {
    'x-ms-date': new Date().toUTCString(),
  },
});

const createListPath = appId => `${appId}/experiences/list.json`;

const createExperiencePath = (appId, experienceId) =>
  `${appId}/experiences/e-${experienceId}.json`;

const getFromPath = async path => {
  let res;
  try {
    const { data } = await client.get(path, generateDefaultConfig());
    res = data;
  } catch (e) {
    console.error(e);
  }
  return res;
};

const getList = async appId => getFromPath(createListPath(appId));

const get = async (appId, experienceId) =>
  getFromPath(createExperiencePath(appId, experienceId));

const save = async (
  content,
  appId,
  experienceName,
  experienceId = undefined,
) => {
  try {
    const config = generateDefaultConfig();
    const isNewExpeience = !experienceId;
    const id = experienceId || uniqueId();
    await client.post(createExperiencePath(appId, id), content, config);
    // TODO: HOW TO UPDATE/CREATE A BLOB FILE?
    if (isNewExpeience) {
      const list = await getList(appId);
      list.push({ id, name: experienceName });
      await client.post(createListPath(appId), list, config);
    }
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const remove = async (appId, experienceId) => {
  try {
    const config = generateDefaultConfig();
    await client.delete(createExperiencePath(appId, experienceId), config); // TODO: HOW TO DELETE A BLOB FILE?
    const list = await getList(appId);
    const newList = list.filter(x => x.id !== experienceId);
    await client.post(createListPath(appId), newList, config);
  } catch (e) {
    console.error(e);
  }
};

const ExperiencesBlobService = {
  getList,
  get,
  save,
  remove,
};

export default ExperiencesBlobService;
