import React from 'react';

// Core
import CmtCardHeader from '../../@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '../../@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '../../@coremat/CmtAdvCard';
import GridContainer from '../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';

// Components
import { ColdVsHotUsersBarGraph } from './ColdVsHotUsersBarGraph';

export const ColdVsHotUsers = ({ data, yAxisKeys, xAxisKey }) => {
  return (
    <CmtAdvCard>
      <CmtCardHeader title="Cold vs. Hot Users" />
      <CmtAdvCardContent>
        <GridContainer>
          <Grid item xs={12}>
            <ColdVsHotUsersBarGraph data={data} yAxisKeys={yAxisKeys} xAxisKey={xAxisKey} />
          </Grid>
        </GridContainer>
      </CmtAdvCardContent>
    </CmtAdvCard>
  );
};
