/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent, Grid, makeStyles, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import Audience from '../../../components/campaign/Audience';
import Stage from '../../../components/campaign/Stage';
import Design from '../../../components/campaign/Design';
import Review from '../../../components/campaign/Review';
import useQueryHook from 'hooks/useQuery';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { useDispatch } from 'react-redux';
import { saveExperience } from 'redux/reducers/Experience';

const useStyles = makeStyles(() => ({
  container: {
    background: '#EAEDFF',
  },
  stepper: {
    background: '#EAEDFF',
  },
  card: {
    // width: '90%',
    height: 200,
  },
}));

const steps = [
  {
    id: 'stage',
    label: 'Stage',
    component: Stage,
  },
  {
    id: 'audience',
    label: 'Audience',
    component: Audience,
  },
  {
    id: 'design',
    label: 'Design',
    component: Design,
  },
  {
    id: 'launch',
    label: 'Launch',
    component: Review,
  },
];

const getStepIndex = stepId => {
  return steps.findIndex(({ id }) => id === stepId) || 0;
};

const NewCampaign = ({ match }) => {
  // const requestedUrl = match.url.replace(/\/$/, '');
  const history = useHistory();
  const query = useQueryHook();
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryStep = query.get('step');

  const [activeStep, setActiveStep] = useState(getStepIndex(queryStep));

  useEffect(() => {
    if (!queryStep) {
      query.set('step', steps[0].id);
      setActiveStep(0);
      history.push({ search: query.toString() });
    }
  }, [queryStep]);

  const getCurrentStepComponent = () => {
    const Component = steps[activeStep >= 0 ? activeStep : 0]?.component;
    return <Component />;
  };
  const onNext = () => {
    if (steps.length - 1 > activeStep) {
      setActiveStep(activeStep + 1);
      const nextStepId = steps[activeStep + 1].id;
      history.push({ search: `?step=${nextStepId}` });
    } else if (steps.length - 1 === activeStep) {
      dispatch(saveExperience({ appId: 100 }));
    }
  };
  const onPrev = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      const prevStepId = steps[activeStep - 1].id;
      history.push({ search: `?step=${prevStepId}` });
    }
  };

  return (
    <Grid container spacing={8}>
      <Grid xs={12} item container classes={{ root: classes.container }}>
        <Grid item xs={12}>
          <Stepper activeStep={activeStep} classes={{ root: classes.stepper }}>
            {steps.map(({ label, stepProps, labelProps }) => (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>

      <Grid xs={12} item container spacing={3}>
        {getCurrentStepComponent()}
      </Grid>
      <Grid item container xs={12} spacing={4} justify="center" alignItems="center">
        <Grid item>
          <Button color="primary" variant="contained" onClick={onPrev} startIcon={<NavigateBeforeIcon />}>
            Back
          </Button>
        </Grid>
        <Grid item>
          <Button color="primary" variant="contained" onClick={onNext} endIcon={<NavigateNextIcon />}>
            {steps.length - 1 === activeStep ? 'Launch' : 'Next'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

NewCampaign.propTypes = {};

export default NewCampaign;
