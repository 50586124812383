/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import values from 'lodash/values';
// eslint-disable-next-line import/no-unresolved
import ExperiencesService from 'services/experinces';
// import { createRequestPayload } from 'services/experinces/helpers';

const availableSegments = {
  1: {
    name: 'NEEDS PROOF',
    id: 1,
  },
  2: {
    name: '>=80',
    id: 2,
    logic: '{"and":[{">=":[{"var":"score"},80]}]}',
  },
  3: {
    name: '<=30',
    id: 3,
    logic: '{"and":[{"<=":[{"var":"score"},30]}]}',
  },
  4: {
    name: 'NOT SCORED',
    id: 4,
    logic: '{"and":[{"!":{"var":"score"}}]}',
  },
  5: {
    name: 'GOING TO BUY',
    id: 5,
  },
  6: {
    name: 'DISCOUNT SEEKER',
    id: 6,
  },
  7: {
    name: 'WILL GET IT LATER',
    id: 7,
  },
  8: {
    name: 'NOT GOING TO BUY',
    id: 8,
  },
  9: {
    name: 'WINDOW SHOPPER',
    id: 9,
  },
};

export const DESIGN_TYPES = {
  INSTA: { id: 'INSTA', html: '' },
  CYBER: { id: 'CYBER', html: '' },
};

const initialState = {
  error: '',
  loading: false,
  name: '',
  description: '',
  stage: {},
  audience: {
    availableSegments,
    selected: {},
    userActionTrigger: 'exitIntent',
    idleTimespan: undefined,
    deliveryType: undefined,
  },
  design: {
    type: undefined,
    html: '',
    css: '',
    js: '',
  },
};

export const REDUCER_NAME = 'experience';

export const saveExperience = createAsyncThunk(
  'propensely/saveExperience',
  async (payload, { dispatch, getState }) => {
    try {
      // transformation etc..
      const data = getState()[REDUCER_NAME];
      // const d = createRequestPayload(data);

      const res = await ExperiencesService.saveExperience({
        ...data,
        ...payload,
      });
      console.log(res);
    } catch (err) {
      console.error(err);
    }
  },
);

export const getSegments = createAsyncThunk(
  `${REDUCER_NAME}/getSegments`,
  // eslint-disable-next-line consistent-return
  async data => {
    try {
      const { appId, state: filterState = 'active' } = data;
      const segments = await ExperiencesService.getSegments(appId, filterState);
      return segments.filter(({ state }) => state === filterState);
    } catch (err) {
      console.log(err);
    }
  },
);

const experienceSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setStage: (state, action) => {
      state.stage = action.payload;
    },
    setAudience: (state, action) => {
      state.audience = action.payload;
    },
    setDesign: (state, action) => {
      state.design.html = action.payload;
    },
    addSegment: (state, action) => {
      const { id } = action.payload;
      state.audience.selected[id] = action.payload;
      delete state.audience.availableSegments[id];
    },
    removeSegment: (state, action) => {
      const { id } = action.payload;
      state.audience.availableSegments[id] = action.payload;
      delete state.audience.selected[id];
      // state.audience.selected = state.audience.selected.filter(s => s.id !== action.payload);
    },
    setIdleTimespan: (state, action) => {
      state.audience.idleTimespan = action.payload;
    },
    setUserActionTrigger: (state, action) => {
      state.audience.userActionTrigger = action.payload;
    },
    setDeliveryType: (state, action) => {
      state.audience.deliveryType = action.payload;
    },
    setDesignType: (state, action) => {
      const { type, html, js, css } = action.payload;
      state.design.type = type;
      state.design.html = html;
      state.design.css = css;
      state.design.js = js;
    },
  },
  extraReducers: {
    [saveExperience.pending]: state => {
      state.loading = true;
    },
    [saveExperience.fulfilled]: (state, action) => {
      const { payload } = action;
      console.log(payload);
      state.loading = false;
      // set state props here
    },
    [saveExperience.rejected]: state => {
      state.loading = false;
    },
    [getSegments.pending]: state => {
      state.loading = true;
    },
    [getSegments.fulfilled]: (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.audience.availableSegments = payload;
    },
    [getSegments.rejected]: state => {
      state.loading = false;
    },
  },
});

const experienceState = state => state[REDUCER_NAME];

export const experienceSelector = experienceState;
export const audienceSelector = createSelector(
  experienceState,
  ({ audience }) => audience,
);
export const selectedSegmentsSelector = createSelector(
  audienceSelector,
  ({ selected }) => values(selected),
);
export const designSelector = createSelector(
  experienceState,
  ({ design }) => design,
);
export const stageSelector = createSelector(
  experienceState,
  ({ stage }) => stage,
);

export const {
  setDescription,
  setName,
  setStage,
  setAudience,
  setDesign,
  addSegment,
  removeSegment,
  setIdleTimespan,
  setUserActionTrigger,
  setDeliveryType,
  setDesignType,
} = experienceSlice.actions;

export default experienceSlice.reducer;
