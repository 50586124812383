import React from 'react';

import { Route, Switch } from 'react-router';
import NewCampaign from './New';
import ExperienceHome from '../../components/campaign/Home';

const Modules = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');

  return (
    <Switch>
      <Route path={`${requestedUrl}/new`}>
        <NewCampaign match={match} />
      </Route>
      <Route path="/">
        <ExperienceHome />
      </Route>
    </Switch>
  );
};

export default Modules;
