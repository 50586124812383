import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, { history } from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import Routes from './routes';
import keycloak from './keycloak';

export const store = configureStore();

window.keycloak = keycloak;

const App = () => (
  <ReactKeycloakProvider authClient={keycloak}>
    <AppContextProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AppWrapper>
            <Switch>
              <Routes />
            </Switch>
          </AppWrapper>
        </ConnectedRouter>
      </Provider>
    </AppContextProvider>
  </ReactKeycloakProvider>
);

export default App;
