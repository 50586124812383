/* eslint-disable */
import { useEffect, useState } from 'react';
import ExperiencesService from '../services/experinces';

export const useExperiences = appId => {
  const [experiences, setExperiences] = useState([]);

  const fetch = async () => {
    if (!appId) return;

    const list = await ExperiencesService.getExperiences(appId);

    const { experiences } = list || {};
    if (experiences) {
      setExperiences(experiences);
    }
  };

  useEffect(() => {
    async function _fetch() {
      fetch();
    }

    _fetch();
  }, [appId]);

  return {
    experiences,
  };
};

export default useExperiences;
