// import { applyMiddleware, createStore } from 'redux';
// import thunk from 'redux-thunk';
// import { createBrowserHistory } from 'history';
// import { routerMiddleware } from 'connected-react-router';
// import reducers from '../reducers';

// const history = createBrowserHistory();
// const routeMiddleware = routerMiddleware(history);
// const bindMiddleware = middleware => {
//   if (process.env.NODE_ENV !== 'production') {
//     const { composeWithDevTools } = require('redux-devtools-extension');
//     return composeWithDevTools(applyMiddleware(...middleware));
//   }
//   return applyMiddleware(...middleware);
// };

// function configureStore(initialState = {}) {
//   const store = createStore(reducers(history), initialState, bindMiddleware([routeMiddleware, thunk]));
//   return store;
// }
// export default configureStore;
// export { history };

import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
// import monitorReducersEnhancer from './enhancers/monitorReducers';
// import loggerMiddleware from './middleware/logger';
import { createBrowserHistory } from 'history';
import reducers from '../reducers';

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: reducers(history),
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware(),
      routeMiddleware,
    ],
    preloadedState,
    // enhancers: [monitorReducersEnhancer],
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(reducers(history)),
    );
  }

  return store;
}
export { history };
