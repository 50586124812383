/* eslint-disable */

import React, { useEffect } from 'react';
import 'grapesjs/dist/css/grapes.min.css';
import PropTypes from 'prop-types';
import grapesjs from 'grapesjs';
import temp from 'grapesjs-preset-webpage';
import { Button } from '@material-ui/core';
import { forwardRef } from 'react';

let editor;

const HtmlEditor = ({ onEditorChange }) => {
  useEffect(() => {
    editor = grapesjs.init({
      container: '#gjs',
      panels: {
        id: 'basic-actions',
        el: '.gjs-pn-views-container',
        buttons: [
          {
            id: 'alert-button',
            className: 'btn-alert-button',
            label: 'Click my butt(on)',
            command(editor) {
              alert('Hello World');
            },
          },
        ],
      },
      // panels: { defaults: [] },
      plugins: ['gjs-preset-webpage'],
      pluginsOpts: {
        'gjs-preset-webpage': {
          blocksBasicOpts: { blocks: ['column1', 'column2', 'column3', 'column3-7', 'text', 'link', 'image'] },
          countdownOpts: {
            dateInputType: 'date',
          },
        },
      },
      blocks: ['link-block', 'quote', 'text-basic'],
      storageManager: {
        id: 'propensely-', // Prefix identifier that will be used inside storing and loading
        type: 'local', // Type of the storage
        autosave: true, // Store data automatically
        autoload: true, // Autoload stored data on init
        stepsBeforeSave: 1, // If autosave enabled, indicates how many changes are necessary before store method is triggered
        storeComponents: true, // Enable/Disable storing of components in JSON format
        storeStyles: true, // Enable/Disable storing of rules in JSON format
        storeHtml: true, // Enable/Disable storing of components as HTML string
        storeCss: true, // Enable/Disable storing of rules as CSS string
      },
    });
    editor.Panels.getPanels().forEach(item => console.log(item.get('id')));
    editor.Panels.addButton('options', [
      {
        id: 'save',
        className: 'fa fa-floppy-o icon-blank',
        label: 'Click my butt(on)',
        command: function(editor1, sender) {
          alert('Hello World');
        },
        attributes: { title: 'Save Template' },
      },
    ]);

    // if (onEditorChange) {
    //   onInit(onEditorChange);
    // }
    editor.on('storage:start', () => {
      const html = editor.getHtml();
      const css = editor.getCss();
      const js = editor.getJs();
      onEditorChange({ html, css, js });
    });
  }, []);
  const onSave = () => {
    const html = editor.getHtml();
    const css = editor.getCss();
    const js = editor.getJs();
    console.log(html);
    console.log(css);
    console.log(js);
  };
  return (
    <React.Fragment>
      <Button onClick={onSave}>Save</Button>
      <div id="gjs"></div>
      <div id="mypanel"></div>
    </React.Fragment>
  );
};

HtmlEditor.propTypes = {
  onEditorChange: PropTypes.func,
};

HtmlEditor.defaultProps = {
  onEditorChange: () => {},
};

export default forwardRef(HtmlEditor);
