/* eslint-disable */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import { designSelector, DESIGN_TYPES, setDesignType } from '../../redux/reducers/Experience';
import HtmlEditor from '../HtmlEditor';
import QueryBuilder from '../QueryBuilder';
import { Card, CardContent, Paper } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

const designTypes = values(DESIGN_TYPES);

const Design = props => {
  const [showEditor, setShowEditor] = useState(false);
  const { type, html } = useSelector(designSelector);
  const dispatch = useDispatch();

  const onDesignSelect = designType => {
    dispatch(setDesignType(designType));
  };
  const onEditorChange = data => {
    dispatch(setDesignType(data));
  };
  return (
    <>
      {/* <QueryBuilder /> */}
      <HtmlEditor onEditorChange={onEditorChange} />

      {/* {designTypes.map(designType => (
        <Paper elevation={type === designType.id ? 15 : 5}>
          <Card elevation={5} variant="outlined" onClick={() => onDesignSelect(designType)}>
            <CardContent style={{ height: '100%' }}>Some content here {designType.id}</CardContent>
          </Card>
        </Paper>
      ))} */}
    </>
  );
};

Design.propTypes = {};

export default Design;
