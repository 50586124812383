/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { IntlProvider } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  createMuiTheme,
  jssPreset,
  StylesProvider,
} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import rtl from 'jss-rtl';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useDispatch, useSelector } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import isEmpty from 'lodash/isEmpty';
import { currentUserSelector, getUserInfo } from '../../../redux/reducers/User';
import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
import PageLoader from '../PageComponents/PageLoader';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// eslint-disable-next-line react/prop-types
const AppWrapper = ({ match, location, children }) => {
  const { theme, locale, direction } = useContext(AppContext);
  const currentAppLocale = AppLocale[locale.locale];
  const user = useSelector(currentUserSelector);
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();
  const [appInitialized, setAppInitialized] = useState(false);

  useEffect(() => {
    if (direction === 'rtl') {
      document.body.setAttribute('dir', 'rtl');
    } else {
      document.body.setAttribute('dir', 'ltr');
    }
  }, [direction]);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const getUser = async () => {
      if (!isEmpty(user)) return user;
      await dispatch(getUserInfo(keycloak));
      setAppInitialized(true);
    };

    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        getUser();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, keycloak.authenticated, user]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ThemeProvider theme={createMuiTheme(theme)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <StylesProvider jss={jss}>
            <CssBaseline />
            {appInitialized ? (
              <AppLayout>{children}</AppLayout>
            ) : (
              <PageLoader />
            )}
          </StylesProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default AppWrapper;
