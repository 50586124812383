/* eslint-disable */

import React, { useState } from 'react';
import CmtCard from '../../@coremat/CmtCard';
import CmtCardHeader from '../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import RecentTable from './RecentTable';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getTodayDate, getYesterdayDate } from '../../@jumbo/utils/dateHelper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import MarketingChannelScoreTable from './MarketingChannelScoreTable';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  scrollbarRoot: {
    height: '100%',
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,

    display: 'inline-block',
  },
}));

const MarketingChannelScoreCard = ({ data }) => {
  const classes = useStyles();
  const sortedData = data.sort(function(a, b) {
    return b.average - a.average;
  });
  return (
    <CmtCard className={classes.card}>
      <CmtCardHeader
        className="pt-4"
        title="Marketing Channels by model score"
        titleProps={{
          variant: 'h4',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent>
        <PerfectScrollbar className={classes.scrollbarRoot}>
          <MarketingChannelScoreTable tableData={data} />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};

export default MarketingChannelScoreCard;
