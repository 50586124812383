/* eslint-disable */

import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import { Dashboard } from 'components/Dashboard';
import { EngagementModelProvider } from 'contexts/EngagementModalContext';
import { useSelector } from 'react-redux';
import { userAppSelector } from 'redux/reducers/User';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Conversion Page', isActive: true },
];

const SamplePage = () => {
  const appInfo = useSelector(userAppSelector);
  const appId = appInfo.app;
  return (
    <EngagementModelProvider appId={appId}>
      <PageContainer
        heading={<IntlMessages id="pages.conversion" />}
        breadcrumbs={[]}>
        <Dashboard />
      </PageContainer>
    </EngagementModelProvider>
  );
};

export default SamplePage;
