import getClient from '../../utils/azure/azureBlobClient';
import { createRequestPayload } from '../helpers';

// const basePath = 'https://propensely-dashboard-ci.azurewebsites.net';

const generateDefaultConfig = () => ({
  withCredentials: true,
  //   crossdomain: true,
  headers: {
    'x-ms-date': new Date().toUTCString(),
    Cookie:
      '.AspNetCore.Cookies=value; CfDJ8Ia9vE0VfqZLh56mDMGqdpDjLIvRn83QLWTgY_siHX3YQX2oYthVsfxhBQD59LCH1Ax8SVY-UueM3bMEI2yx0L0S2q9aF8A4sbiGxN6KBl3Fs_SzOH5YLh1USNYiKpQ1Fp38aXDJjZijeXC2uavQoVOnHSHz8RkSzAp0YhoT_NgwKiezSRKEBfXxbG5eXpqd-tuqR8qqwFtaG5pXaaiVBXMiSd4nSKkoLQJykPWWUyq0KT8r7UjF0aZdKNgR5zk3pBfBOmi0MrOKdo4edDs_SY6lSMUUHviZFznniKhP3g58_K2PL1GUVDscCQ0bXb7vXXxPQ_m4nx3t4xWGhSXDnSPrVIEeKoPPHOrJ3NVZjP-lg3-LvI0B5GjdDZ-57zlYsOuxK_xcgOaD4sn9OEv_0i0Zp0NhLiH6keLyxX7bxQaRVqIVCZ1O3GNtm0O8TJr-5vb9_bU8fL-Yg5l3Yzn_i8zyuxSW-kVn4ubDi0UwIlDA6gzzKqZXBKuX_TakSsNdHZxe_p7hgxwwqk0LBkdQBsWz8-cgSVYzi2BeBmDFDQ7uKT5wL0xLVHMGAs48uTrYPPhE4NBz0OHvHm1EaijMN7vG_R0B2xZsLFZh40dikTGbDlxg3-cJK_LOXKreXj9d5unS3IswUBmiPvEemP0OhFoXVfWaX7vMNGVqLR3PrbU0tlmkC9Q75ihIBd0VSSYU5v917BScPAy6tJ8_xh5N3rmaJEJonaPFH-uWm2TxBUDGhsTUlgD140bKByrhY7ktKFdL0D7HFXE__caTB2CG30bsd4jlCeYv2xmSRR98T0-cDVe79CvAUyES0c8JcvsXYF9fezqpTg6Y1evx874RSSE',
  },
});

const tempLogInToApi = async appId => {
  await fetch('/backdoor/login', {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify({
      userId: '394',
      appId,
      firstname: 'Ammar',
      lastname: 'Hamidou',
      roles: ['system-admin'],
    }),
  });
};

export const getExperiences = async appId => {
  let res;

  try {
    await tempLogInToApi(appId);
    const client = await getClient(appId, 'dashboard');
    const { data } = await client.get(
      `http://localhost:3000/apps/${appId}/experiences`,
      generateDefaultConfig(),
    );
    res = data;
  } catch (e) {
    console.error(e);
  }
  return res;
};

export const getSegments = async (appId, _state = 'active') => {
  let res;
  try {
    await tempLogInToApi();
    const client = await getClient(appId, 'dashboard');
    const { data } = await client.get(
      `http://localhost:3000/apps/${appId}/segments?includeDetails=false`,
      generateDefaultConfig(),
    );
    res = data;
  } catch (e) {
    console.error(e);
  }
  return res;
};

export const saveExperience = async state => {
  const payload = createRequestPayload(state);
  const { id, appId } = state;
  await tempLogInToApi();
  const client = await getClient(appId, 'dashboard');
  if (id) {
    return client.put('');
  }
  return client.post(
    `http://localhost:3000/apps/${appId}/experiences`,
    payload,
  );
};
