/* eslint-disable no-console */
import React from 'react';
// import axios from 'axios';
// eslint-disable-next-line import/no-unresolved
import keycloak from 'keycloak';
// eslint-disable-next-line import/no-unresolved
import { USER_LOCAL_STORAGE_KEY } from 'utils/constants';

import { fetchStart, fetchSuccess } from '../../../redux/actions';
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser,
} from '../../../redux/actions/Auth';

const BasicAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        const user = { name, email, password };
        localStorage.setItem('user', JSON.stringify(user));
        dispatch(setAuthUser(user));
      }, 300);
    };
  },

  onLogin: ({ email, password }) => {
    console.log(email);
    console.log(password);
    // return dispatch => {
    //   try {
    //     dispatch(fetchStart());
    //     axios
    //       .post('https://api-propensely-dev-js.azurewebsites.net/api/auth', {
    //         username: email,
    //         password: password,
    //       })
    //       .then(({ data }) => {
    //         if (data && data.u) {
    //           data = { ...data, email };
    //           localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(data));
    //           dispatch(setAuthData(data));
    //           dispatch(setAuthUser(data));
    //           dispatch(fetchSuccess());
    //         } else {
    //           dispatch(fetchError('Login failed, please check your username and password.'));
    //         }
    //       });
    //   } catch (error) {
    //     dispatch(fetchError(error.message));
    //   }
    // };
  },
  onLogout: () => {
    return dispatch => {
      // dispatch(fetchStart());
      console.log(keycloak);

      keycloak.logout();
      // setTimeout(() => {
      //   dispatch(fetchSuccess());
      //   localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
      //   dispatch(setAuthUser(null));
      // }, 300);
    };
  },

  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(
          setAuthUser(JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY))),
        );
      }, 300);
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <> </>;
  },
};

export default BasicAuth;
