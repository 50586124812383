import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import PeopleIcon from '@material-ui/icons/People';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CmtAvatar from '@coremat/CmtAvatar';
import { makeStyles } from '@material-ui/core/styles';
import AppleIcon from '@material-ui/icons/Apple';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import TabletMacIcon from '@material-ui/icons/TabletMac';

const useStyles = makeStyles({
  scoreCol: {
    verticalAlign: 'top',
  },
});

const MARKETING_CHANNEL_ICON_MAP = {
  iPhone: { component: AppleIcon, color: '#A3AAAE' },
  MacIntel: { component: DesktopMacIcon, color: '#A3AAAE' },
  iPad: { component: TabletMacIcon, color: '#A3AAAE' },
};

const getIcon = key => {
  const Comp = MARKETING_CHANNEL_ICON_MAP[key] || PeopleIcon;
  const { component: Component, color, path } = Comp;
  if (Component) {
    return (
      <CmtAvatar style={{ backgroundColor: color }}>
        <Component />
      </CmtAvatar>
    );
  } else {
    return <CmtAvatar size={46} src={path} />;
  }
};

const DeviceScoreTable = ({ tableData = [] }) => {
  const classes = useStyles();

  return (
    <Box className="Cmt-table-responsive">
      <Table style={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography>Device</Typography>
              <Typography color="textSecondary" variant="caption">
                # of Users
              </Typography>
            </TableCell>
            <TableCell align="right" className={classes.scoreCol}>
              <Typography>Avg Score</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.length > 0 ? (
            tableData.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="left" style={{ display: 'flex', minWidth: '250px' }}>
                  <Grid container spacing={6} alignItems="center">
                    <Grid item>{getIcon(row.name)}</Grid>
                    <Grid item style={{ flex: 1, padding: 0 }}>
                      <Typography style={{ textOverflow: 'ellipsis' }}>{row.name}</Typography>
                      <Typography color="textSecondary" variant="caption">
                        {row.totalVisitors} Visitors
                      </Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align="right">
                  <Typography>{Math.round(row.average)}</Typography>
                  <Typography color="textSecondary" variant="caption">
                    out of 100
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell style={{ display: 'flex', minWidth: '300px' }}>
                <Typography align="center">Not enough data</Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default DeviceScoreTable;
