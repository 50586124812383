import React, { createContext, useContext, useEffect, useState } from 'react';

import { useModelDataResource } from '../hooks/useModelDataResource';

import {
  createAggregatedGroupedDataBuckets,
  createAggregatedSummedDataBuckets,
} from '../components/EngagementModel/utils';

export const EngagementModelContext = createContext();
export const useEngagementModel = () => useContext(EngagementModelContext);

// eslint-disable-next-line react/prop-types
export const EngagementModelProvider = ({ children, appId }) => {
  const {
    isLoading,
    groupedUserScores,
    featureImportance,
    marketingChannels,
    modelAccuracy,
    browsers,
    devices,
  } = useModelDataResource(appId, 'engagement');

  const [aggregatedGroupedData, setAggregatedGroupedData] = useState({
    data: [],
    yAxisKeys: [],
    xAxisKey: '',
  });
  const [aggregatedSummedData, setAggregatedSummedData] = useState({
    data: [],
    yAxisKeys: [],
    xAxisKey: '',
  });

  useEffect(
    () =>
      setAggregatedSummedData(
        createAggregatedSummedDataBuckets(groupedUserScores, 'totalVisitors'),
      ),
    [groupedUserScores],
  );

  useEffect(
    () =>
      setAggregatedGroupedData(
        createAggregatedGroupedDataBuckets(groupedUserScores),
      ),
    [groupedUserScores],
  );

  const value = {
    isLoading,
    aggregatedGroupedData,
    aggregatedSummedData,
    featureImportance,
    marketingChannels,
    modelAccuracy,
    browsers,
    devices,
  };

  return (
    <EngagementModelContext.Provider value={value}>
      {children}
    </EngagementModelContext.Provider>
  );
};
