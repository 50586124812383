// error: '',
//   loading: false,
//   name: '',
//   description: '',
//   stage: {},
//   audience: {
//     availableSegments,
//     selected: {},
//     userActionTrigger: 'exitIntent',
//     idleTimespan: undefined,
//     deliveryType: undefined,
//   },
//   design: {
//     type: undefined,
//     html: '',
//     css: '',
//     js: '',
//   },

// payload:
// const aa = {
//   audience: {
//     segmentIds: ['string'],
//     trigger: {
//       type: 'notSet',
//       metadata: {
//         additionalProp1: 'string',
//         additionalProp2: 'string',
//         additionalProp3: 'string',
//       },
//     },
//   },
//   description: 'string',
//   executionJuncture: 'beforeVisit',
//   name: 'string',
//   rendition: {
//     type: 'notSet',
//     html: 'string',
//     css: 'string',
//     js: 'string',
//   },
// };

const getTrigger = audience => {
  const { userActionTrigger, idleTimespan } = audience;
  const props = {};
  if (userActionTrigger === 'idle') {
    props.idleTimespan = idleTimespan;
  }

  return {
    type: userActionTrigger,
    metadata: props,
  };
};

const getRendition = design => {
  const { html, css, js } = design;
  return {
    html,
    css,
    js,
  };
};

export const createRequestPayload = state => {
  const { audience, description, name, design, stage } = state;
  const { selected } = audience;

  const trigger = getTrigger(audience);
  const rendition = getRendition(design);
  // eslint-disable-next-line no-new-wrappers
  const segmentIds = Object.values(selected).map(seg => new String(seg.id));

  return {
    audience: {
      segmentIds,
      trigger,
    },
    description,
    executionJuncture: stage.id,
    name,
    rendition,
  };
};

export const createSegmentPayload = () => {};
