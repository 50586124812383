/* eslint-disable */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Radio,
} from '@material-ui/core';
import { CAMPAIGN_STAGES, EXPIRIENCE_TYPE_MAP } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { setStage } from 'redux/reducers/Experience';
import GroupIcon from '@material-ui/icons/Group';
import Fab from '@material-ui/core/Fab';
import Avatar from '@material-ui/core/Avatar';
import { ReactComponent as VisitorOnSite } from '../../../src/images/visitorAcquisition.svg';
import { ReactComponent as VisitorAcquisition } from '../../../src/images/visitorAcquisition.svg';

const useStyles = makeStyles(() => ({
  card: {
    // width: '90%',
    minHeight: 219,
  },
}));

const ICONS_MAP = {
  [EXPIRIENCE_TYPE_MAP.DuringVisit]: VisitorOnSite,
  [EXPIRIENCE_TYPE_MAP.BeforeVisit]: GroupIcon,
};

const getIcon = (id = EXPIRIENCE_TYPE_MAP.DuringVisit) => {
  const Icon = ICONS_MAP[id];
  return <Icon fontSize="large" color="primary" width="30px" height="30px" />;
};

const Stage = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentStage = useSelector(state => state.experience.stage);
  const onStageSelect = stage => {
    dispatch(setStage(stage));
  };
  const stages = CAMPAIGN_STAGES.length;
  return (
    <React.Fragment>
      {CAMPAIGN_STAGES.map(stage => (
        <Grid item xs={12} md={12 / stages} key={stage.id}>
          <Paper elevation={currentStage?.id === stage.id ? 15 : 5}>
            <Card elevation={5} classes={{ root: classes.card }} variant="outlined" onClick={() => onStageSelect(stage)}>
              <CardContent style={{ height: '100%' }}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Radio checked={currentStage?.id === stage.id} style={{ padding: 0 }} color="primary" />
                  </Grid>
                  <Grid item xs={11} container alignItems="flex-start" style={{ paddingTop: '3%' }} spacing={5}>
                    <Grid container item xs={3} justify="center" alignItems="center">
                      <Grid item>
                        <Avatar style={{ backgroundColor: '#9BE7FD', width: 80, height: 80 }}>
                          {getIcon(stage.id)}
                          {/* <GroupIcon fontSize="large" color="primary" /> */}
                          {/* <VisitorOnSite width="60px" height="60px" /> */}
                        </Avatar>
                      </Grid>
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container spacing={5}>
                        <Grid item>
                          <Typography align="left" variant="h4" component="h4">
                            {stage.header}
                          </Typography>
                          <Box pt={2} />
                          <Typography align="left" variant="body2" component="p">
                            {stage.content}
                          </Typography>
                        </Grid>
                        {/* <Grid item>
                        <Typography align="center" variant="h3" component="h2">
                          {stage.header}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography align="center" variant="body2" component="p">
                          {stage.content}
                        </Typography>
                      </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      ))}
    </React.Fragment>
  );
};

Stage.propTypes = {};

export default Stage;
