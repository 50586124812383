import React, { useRef, useEffect } from 'react';
import CmtCard from '../../@coremat/CmtCard';
import CmtCardHeader from '../../@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '../../@coremat/CmtCard/CmtCardContent';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import UserBrowserScoreTable from './UserBrowserScoreTable';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
  },
  titleRoot: {
    letterSpacing: 0.15,
  },
  scrollbarRoot: {
    height: 500,
    '& .ps__rail-x': {
      opacity: 0.6,
    },
    '& .ps__rail-y': {
      opacity: 0.6,
    },
  },
  badgeRoot: {
    color: theme.palette.common.white,
    borderRadius: 30,
    fontSize: 12,

    display: 'inline-block',
  },
}));

const UserBrowserScoreCard = ({ data }) => {
  const classes = useStyles();
  let _scrollRef = useRef(null);
  const sortedData = data.sort(function(a, b) {
    return b.average - a.average;
  });

  useEffect(() => {
    if (_scrollRef) {
      _scrollRef.current.scrollTop = 8;
    }
  }, [_scrollRef]);

  return (
    <CmtCard className={classes.card}>
      <CmtCardHeader
        className="pt-4"
        title="Browsers by model score"
        titleProps={{
          variant: 'h4',
          component: 'div',
          className: classes.titleRoot,
        }}
      />
      <CmtCardContent>
        <PerfectScrollbar
          containerRef={ref => {
            _scrollRef.current = ref;
          }}
          className={classes.scrollbarRoot}>
          <UserBrowserScoreTable tableData={sortedData} />
        </PerfectScrollbar>
      </CmtCardContent>
    </CmtCard>
  );
};

export default UserBrowserScoreCard;
