/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { USER_FIELDS } from '../../utils/constants';
// eslint-disable-next-line import/no-unresolved
// import { createRequestPayload } from 'services/experinces/helpers';

const initialState = {
  error: '',
  loading: false,
  user: {},
};

export const REDUCER_NAME = 'user';

export const getUserInfo = createAsyncThunk(
  `${REDUCER_NAME}/getUserInfo`,
  // eslint-disable-next-line consistent-return
  async keycloak => {
    try {
      const userInfo = await keycloak.loadUserInfo();
      console.log(userInfo);
      return userInfo;
    } catch (err) {
      console.log(err);
    }
  },
);

const userSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    setDescription: (state, action) => {
      state.description = action.payload;
    },
  },
  extraReducers: {
    [getUserInfo.pending]: state => {
      state.loading = true;
    },
    [getUserInfo.fulfilled]: (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.user = payload;
      // set state props here
    },
    [getUserInfo.rejected]: state => {
      state.loading = false;
    },
  },
});

export const userSelector = state => {
  return state[REDUCER_NAME];
};

export const currentUserSelector = createSelector(
  userSelector,
  ({ user }) => user,
);

export const userAppSelector = createSelector(currentUserSelector, user => {
  return user[USER_FIELDS.Apps]?.[0];
});

export const { setDescription } = userSlice.actions;

export default userSlice.reducer;
