/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

const Review = props => {
  return <div>This is Review step</div>;
};

Review.propTypes = {};

export default Review;
