const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const generateAggregatedData = (keys = [], rows = 100) => {
  const aggregatedData = [];

  for (let i = 0; i < rows; i++) {
    const data = {};
    keys.forEach(key => (data[key] = getRandomInt(0, 100)));
    aggregatedData.push(data);
  }

  return aggregatedData;
};

export const createAggregatedGroupedDataBuckets = (aggregatedData, bucketSize = 10) => {
  const buckets = [];
  const yAxisKeys = {};

  if (Object.keys(aggregatedData).length === 0) {
    return { data: buckets, yAxisKeys: Object.keys(yAxisKeys), xAxisKey: 'bucket' };
  }

  for (let i = 0; i < 100; i += bucketSize) {
    const bucket = `${i === 0 ? i : i + 1} - ${i + bucketSize}`;
    const bucketData = {};
    for (let j = i; j < i + bucketSize; j++) {
      const aggregatedDataRow = aggregatedData[j] || {};

      Object.entries(aggregatedDataRow).forEach(([key, value]) => {
        if (!yAxisKeys[key]) yAxisKeys[key] = true;

        if (!bucketData[key]) {
          bucketData[key] = 0;
        }

        bucketData[key] += value;
      });
    }
    buckets.push({ bucket, ...bucketData });
  }

  buckets.forEach(bucket => {
    Object.keys(yAxisKeys).forEach(key => {
      if (!bucket[key]) {
        bucket[key] = 0;
      }
    });

    return bucket;
  });

  console.log(buckets);

  return { data: buckets, yAxisKeys: Object.keys(yAxisKeys), xAxisKey: 'bucket' };
};

export const createAggregatedSummedDataBuckets = (aggregatedData, key, bucketSize = 10) => {
  const buckets = [];
  const yAxisKeys = ['total'];

  if (Object.keys(aggregatedData).length === 0) {
    return { data: buckets, yAxisKeys, xAxisKey: 'bucket' };
  }

  for (let i = 0; i < 100; i += bucketSize) {
    const bucket = `${i === 0 ? i : i + 1} - ${i + bucketSize}`;
    const bucketData = { total: 0 };
    for (let j = i; j < i + bucketSize; j++) {
      const aggregatedDataRow = aggregatedData[j] || {};
      bucketData.total += aggregatedDataRow[key] || 0;
    }
    buckets.push({ bucket, ...bucketData });
  }

  console.log(buckets);

  return { data: buckets, yAxisKeys, xAxisKey: 'bucket' };
};
