import getClient from '../../utils/azure/azureBlobClient';

// eslint-disable-next-line import/prefer-default-export
export const AzureDataService = {
  // eslint-disable-next-line consistent-return
  get: async (app, file) => {
    try {
      const client = await getClient(app, 'dashboard');

      const { data } = await client.get(file);

      console.log(data);

      return data;
    } catch (e) {
      console.error(e);
    }
  },
};
