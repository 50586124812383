/* eslint-disable */

import axios from 'axios';
import axiosRetry from 'axios-retry';

const BLOB_API_VERSION = '2019-12-12';
const BLOB_API_RETRY_COUNT = 5;

const blobClients = {};

const createNewClient = async (url = '') => {
  const urlParts = url.split('?');
  const client = axios.create({
    baseURL: urlParts[0],
    headers: {
      'Content-Type': 'application/json',
      'x-ms-version': BLOB_API_VERSION,
    },
  });

  const sasParams = urlParts[1].split('&').reduce(
    (prev, curr) => ({
      ...prev,
      [curr.split('=')[0]]: decodeURIComponent(curr.split('=')[1]),
    }),
    {},
  );

  client.interceptors.request.use(config => {
    config.params = { ...config.params, ...sasParams };
    config.headers = { 'x-ms-date': new Date().toUTCString() };

    return config;
  });

  const retryOptions = {};
  const blobApiRetryCount = Number(BLOB_API_RETRY_COUNT);
  if (!isNaN(blobApiRetryCount)) retryOptions.retries = blobApiRetryCount;
  axiosRetry(client, retryOptions);

  return client;
};

const getBlobConnection = async (app, path) => {
  const connectUrl = `https://api-propensely-connector-staging.azurewebsites.net/api/connect?code=wJ3CObeqaQ9ptNSexQc1pbluDf92XZd3Gw60IQQBBU61tKuFQHR/mQ==&appid=${app}&path=dashboard`;
  const res = await axios.get(connectUrl, {
    headers: {
      jwt: window.keycloak.token,
    },
  });

  if (res && res.data && res.data.u) {
    return res.data.u;
  }
  blobClients[`${app}-${path}`] = null;
  return null;
};

const getNewClient = async (app, path) => {
  blobClients[`${app}-${path}`] = await createNewClient(
    await getBlobConnection(app, path),
  );

  return blobClients[`${app}-${path}`];
};

const getClient = async (app, path) => {
  return blobClients[`${app}-${path}`] || getNewClient(app, path);
};

export default getClient;
