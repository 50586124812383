import React from 'react';

// Core
import CmtCardHeader from '../../@coremat/CmtCard/CmtCardHeader';
import CmtAdvCardContent from '../../@coremat/CmtAdvCard/CmtAdvCardContent';
import CmtAdvCard from '../../@coremat/CmtAdvCard';
import GridContainer from '../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';

// Components
import { ModelVsActionLineGraph } from './ModelVsActionLineGraph';

export const ModelVsAction = ({ data, yAxisKeys, xAxisKey }) => {
  return (
    <CmtAdvCard>
      <CmtCardHeader title="Engagement Model vs. Actions" />
      <CmtAdvCardContent>
        <GridContainer>
          <Grid item xs={12}>
            <ModelVsActionLineGraph data={data} yAxisKeys={yAxisKeys} xAxisKey={xAxisKey} />
          </Grid>
        </GridContainer>
      </CmtAdvCardContent>
    </CmtAdvCard>
  );
};
