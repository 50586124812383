import { getSegments, saveExperience, getExperiences } from './api';
import ExperiencesBlobService from './azure';
// import ExperienceApi from './api';

const ExperiencesService = {
  getExperiences,
  getExperience: ExperiencesBlobService.get,
  saveExperience,
  deleteExperience: ExperiencesBlobService.remove,
  getSegments,
};

export default ExperiencesService;
