/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import values from 'lodash/values';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import initialData from './initial-data';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSegment,
  audienceSelector,
  removeSegment,
  selectedSegmentsSelector,
  setUserActionTrigger,
  setIdleTimespan,
  setDeliveryType,
  setName,
  setDescription,
  experienceSelector,
  getSegments,
} from 'redux/reducers/Experience';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  CardContent,
  Avatar,
  Paper,
} from '@material-ui/core';
import { ReactComponent as ExitIntent } from '../../../src/images/ExitIntent.svg';
import { ReactComponent as FloatingBar } from '../../../src/images/FloatingBar.svg';
import { ReactComponent as FullScreen } from '../../../src/images/FullScreen.svg';
import { ReactComponent as LightboxPopup } from '../../../src/images/LightboxPopup.svg';
import { ReactComponent as SlidingBox } from '../../../src/images/SlidingBox.svg';
import { ReactComponent as LeftArrow } from '../../../src/images/ArrowLeft.svg';
import { userAppSelector } from 'redux/reducers/User';

const useStyles = makeStyles(theme => ({
  lightText: {
    color: '#C4C4C4',
  },
  card: {
    // width: '90%',
    minHeight: 219,
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    minHeight: '200px',
    maxHeight: '300px',
    overflow: 'auto',
    padding: 10,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.8),
    },
  },
}));

const idleTimeSpan = [
  {
    id: '60',
    label: '60 Seconds',
  },
  {
    id: '30',
    label: '30 Seconds',
  },
  {
    id: '90',
    label: '90 Seconds',
  },
];

const experienceDelivery = [
  {
    id: 'lightbox_popup',
    headerLabel: 'Lightbox Popup',
    description: 'Experience will be presented as popup.',
    icon: LightboxPopup,
  },
  {
    id: 'floating_bar',
    headerLabel: 'Floating Bar',
    description:
      'Fix floating bar on the page. Customer can continue to interact with page.',
    icon: FloatingBar,
  },
  {
    id: 'sliding_box',
    headerLabel: 'Sliding Box',
    description:
      'Experience will slide in to page, customer can contiue to interact with page content.',
    icon: SlidingBox,
  },
  {
    id: 'full_screen_takeover',
    headerLabel: 'Full Screen Takeover',
    description:
      'Experience will slide in to page, customer can contiue to interact with page content.',
    icon: FullScreen,
  },
];

const getIcon = delivery => {
  const Icon = delivery.icon;
  return <Icon />;
};

const Audience = props => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { name: experienceName, description } = useSelector(experienceSelector);
  const {
    availableSegments,
    idleTimespan,
    userActionTrigger,
    deliveryType,
  } = useSelector(audienceSelector);
  const selected = useSelector(selectedSegmentsSelector);
  const availableSegmentList = values(availableSegments);
  const appInfo = useSelector(userAppSelector);
  const appId = appInfo.app;

  useEffect(() => {
    dispatch(getSegments({ appId }));
  }, []);

  const handleAddSegment = newSegment => {
    dispatch(addSegment(newSegment));
  };

  const handleRemoveSegment = seg => {
    dispatch(removeSegment(seg));
  };

  const onExperienceDelivery = deliveryOption => {
    dispatch(setDeliveryType(deliveryOption.id));
  };

  const onNameChange = e => {
    dispatch(setName(e.target.value));
  };

  const onDescriptionChange = e => {
    dispatch(setDescription(e.target.value));
  };

  const getIdleRadioContent = () => {
    return (
      <FormControl variant="outlined" style={{ paddingTop: 8 }}>
        <InputLabel htmlFor="outlined-age-native-simple">Idle for</InputLabel>
        <Select
          native
          value={idleTimespan}
          onChange={e => dispatch(setIdleTimespan(e.target.value))}
          label="Idle for"
          inputProps={{
            name: 'idle',
            id: 'outlined-age-native-simple',
          }}>
          {idleTimeSpan.map(item => {
            return <option value={item.id}>{item.label}</option>;
          })}
        </Select>
      </FormControl>
    );
  };

  const getExitIntentLabel = () => {
    return (
      <Grid container direction="column" spacing={4} style={{ paddingTop: 8 }}>
        <Grid item>
          <Typography variant="h4">Intent to exit</Typography>
        </Grid>
        <Grid item>
          <ExitIntent />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container spacing={4}>
      <Grid item container direction="column" spacing={8}>
        <Grid item xs={5}>
          <Typography variant="subtitle1" classes={{ root: classes.lightText }}>
            DEFINE YOUR EXPERIENCE
          </Typography>
          <Box pt={2} />
          <TextField
            fullWidth
            label="Experience Name&#42;"
            variant="outlined"
            value={experienceName}
            onChange={onNameChange}
          />
        </Grid>
        <Grid item xs={10}>
          <TextField
            rows={5}
            fullWidth
            multiline
            label="Experience Description&#42;"
            variant="outlined"
            value={description}
            onChange={onDescriptionChange}></TextField>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid item>
          <Box pt={8} />
          <Typography variant="subtitle1" classes={{ root: classes.lightText }}>
            WHEN USER IDENTIFIED AS
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <Grid container item spacing={8}>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                classes={{ root: classes.lightText }}>
                <i>Experience will be served to:</i>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="subtitle1"
                classes={{ root: classes.lightText }}>
                All Available Segments, Select one or many from list below
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <div className={classes.root}>
                {selected.map(seg => (
                  <Chip
                    key={seg.id}
                    size="small"
                    label={seg.name}
                    clickable
                    color="primary"
                    onClick={() => handleRemoveSegment(seg)}
                    onDelete={() => handleRemoveSegment(seg)}
                  />
                ))}
              </div>
            </Card>
          </Grid>
          <Grid container item xs={1} alignItems="center">
            <Grid item>
              <LeftArrow />
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Card>
              <div className={classes.root}>
                {availableSegmentList.map(seg => (
                  <Chip
                    key={seg.id}
                    size="small"
                    label={seg.name}
                    clickable
                    color="primary"
                    onClick={() => handleAddSegment(seg)}
                    onDelete={() => handleAddSegment(seg)}
                    deleteIcon={<AddCircleIcon />}
                  />
                ))}
              </div>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item>
          <Box pt={6} />
          <Typography variant="subtitle1" classes={{ root: classes.lightText }}>
            WHEN USER
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RadioGroup
            row
            aria-label="userTrigger"
            name="userTrigger"
            value={userActionTrigger}
            onChange={e => dispatch(setUserActionTrigger(e.target.value))}>
            <FormControlLabel
              style={{ alignItems: 'flex-start', paddingLeft: 15 }}
              labelPlacement="end"
              value="exitIntent"
              control={<Radio color="primary" />}
              label={getExitIntentLabel()}
            />
            <FormControlLabel
              style={{ alignItems: 'flex-start', paddingLeft: 15 }}
              labelPlacement="end"
              value="idle"
              control={<Radio color="primary" />}
              label={getIdleRadioContent()}
            />
          </RadioGroup>
        </Grid>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item>
          <Box pt={6} />
          <Typography variant="subtitle1" classes={{ root: classes.lightText }}>
            DELIVER EXPERIENCE AS
          </Typography>
        </Grid>
        <Grid item container spacing={4}>
          {experienceDelivery.map(delivery => {
            return (
              <Grid item xs={12} md={4}>
                <Paper elevation={deliveryType === delivery.id ? 15 : 5}>
                  <Card
                    onClick={() => onExperienceDelivery(delivery)}
                    classes={{ root: classes.card }}
                    variant="outlined">
                    <CardContent style={{ height: '100%' }}>
                      <Grid container spacing={2}>
                        <Grid item xs={1}>
                          <Radio
                            checked={deliveryType === delivery.id}
                            style={{ padding: 0 }}
                            color="primary"
                          />
                        </Grid>
                        <Grid
                          item
                          xs={11}
                          container
                          alignItems="flex-start"
                          style={{ paddingTop: '3%' }}
                          spacing={5}>
                          <Grid
                            container
                            item
                            xs={5}
                            lg={4}
                            justify="center"
                            alignItems="center">
                            <Avatar
                              style={{
                                backgroundColor: '#9BE7FD',
                                width: 120,
                                height: 120,
                              }}>
                              {getIcon(delivery)}
                            </Avatar>
                          </Grid>
                          <Grid item xs={7} lg={8}>
                            <Grid container spacing={5}>
                              <Grid item>
                                <Typography
                                  align="left"
                                  variant="h4"
                                  component="h4">
                                  {delivery.headerLabel}
                                </Typography>
                                <Box pt={2} />
                                <Typography
                                  align="left"
                                  variant="body2"
                                  component="p">
                                  {delivery.description}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

Audience.propTypes = {};

export default Audience;
