export const modules = [
  {
    name: 'Insta Follow',
    type: 'section',
    id: 'insta_follow',
    triggers: [
      {
        type: 'conversion_model',
        props: {
          maxScore: 100,
          minScore: 10,
        },
      },
      {
        type: 'engagement_model',
        props: {
          maxScore: 100,
          minScore: 10,
        },
      },
    ],
    props: [
      {
        label: '',
      },
    ],
  },
  {
    name: 'News Letter signup',
    type: 'section',
    id: 'insta_follow',
    props: [
      {
        label: '',
      },
    ],
  },
];
