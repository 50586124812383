import React from 'react';

// Core
import Box from '@material-ui/core/Box';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';

// Fake Data
const visitorsWithScore = [
  { month: 'Jan', count: 2000 },
  { month: 'Feb', count: 1450 },
  { month: 'Mar', count: 1100 },
  { month: 'Apr', count: 1400 },
  { month: 'May', count: 900 },
  { month: 'Jun', count: 1600 },
  { month: 'Jul', count: 1300 },
  { month: 'Aug', count: 1800 },
  { month: 'Sep', count: 1200 },
  { month: 'Oct', count: 1600 },
];

export const UniqueVisitorsWithScoreGraph = () => {
  return (
    <ResponsiveContainer width="100%" height={120}>
      <LineChart data={visitorsWithScore} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <Tooltip
          cursor={false}
          content={({ active, label, payload }) => {
            return active ? (
              <Box color="#fff">
                {payload.map((row, index) => (
                  <Box key={index}>{`${label}: ${row.value} Visitors`}</Box>
                ))}
              </Box>
            ) : null;
          }}
          wrapperStyle={{
            background: '#0062FF',
            padding: '5px 8px',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        />
        <XAxis dataKey="month" hide />
        <Line dataKey="count" type="monotone" dot={null} strokeWidth={3} stackId="2" stroke="#0062FF" />
      </LineChart>
    </ResponsiveContainer>
  );
};
