export const USER_LOCAL_STORAGE_KEY = 'propensely_user';

export const EXPIRIENCE_TYPE_MAP = {
  DuringVisit: 'DuringVisit',
  BeforeVisit: 'BeforeVisit',
};

export const CAMPAIGN_STAGES = [
  {
    id: EXPIRIENCE_TYPE_MAP.DuringVisit,
    header: 'Visitors who are on my website',
    content:
      'This option allows you to create personilized experiences, offers and discounts to your active website visitors. ',
  },
  {
    id: EXPIRIENCE_TYPE_MAP.BeforeVisit,
    header: 'Bringing visitors to my website Acquisition / Retargeting',
    content:
      'This option allows you to sync propensely segmentation with Adwords or Facebook account, so that you can create look-a-like campaings or retargeting campaings on your Ad account. ',
  },
  // {
  //   id: 'post',
  //   header: 'Post',
  //   content: 'Campaigns which run after the user has visited the site',
  // },
];

export const USER_FIELDS = {
  Sub: 'sub',
  Address: 'address',
  EmailVerified: 'email_verified',
  Name: 'name',
  PreferredName: 'preferred_username',
  FirstName: 'given_name',
  LastName: 'family_name',
  Email: 'email',
  Apps: 'app-name',
};
