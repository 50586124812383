/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Chip from '@material-ui/core/Chip';
import { useHistory } from 'react-router';
import Expiriences from './Data.json';

// const STATUS_COLOR = {
//     "RUNNING" : "#5D9405",
//     ""
// }

const columns = [
  { id: 'id', label: 'ID', minWidth: 50 },
  { id: 'name', label: 'Name', minWidth: 100 },
  //   { id: 'type', label: 'Type', minWidth: 70 },
  //   {
  //     id: 'trigger',
  //     label: 'Trigger',
  //     minWidth: 80,
  //     align: 'right',
  //     format: value => value,
  //   },
  //   {
  //     id: 'segment_score',
  //     label: 'Segment / Score',
  //     minWidth: 80,
  //     align: 'right',
  //     format: value => value,
  //   },
  {
    id: 'startOn',
    label: 'Start Date',
    minWidth: 75,
    align: 'right',
    format: value => value,
  },
  //   {
  //     id: 'status',
  //     label: 'Status',
  //     minWidth: 75,
  //     align: 'right',
  //     format: value => value,
  //     render: value => {
  //       return <Chip size="small" label={value} color="primary" />;
  //     },
  //   },
];

// function createData(id, name, trigger, population, size) {
//   const density = population / size;
//   return { id, name, trigger, population, size, density };
// }

const getExperiences = type => {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(Expiriences), 2000);
  });
};

const getTableRows = (exps = []) => {
  return exps.map(({ id, name, startOn, status }) => ({
    id,
    name,
    startOn,
    status,
  }));
};

// const rows = [
//   createData(1, 'India', 'IN', 1324171354, 3287263),
//   createData(2, 'China', 'CN', 1403500365, 9596961),
//   createData(3, 'Italy', 'IT', 60483973, 301340),
//   createData(4, 'United States', 'US', 327167434, 9833520),
//   createData(5, 'Canada', 'CA', 37602103, 9984670),
//   createData(6, 'Australia', 'AU', 25475400, 7692024),
//   createData(7, 'Germany', 'DE', 83019200, 357578),
//   createData(8, 'Ireland', 'IE', 4857000, 70273),
//   createData(9, 'Mexico', 'MX', 126577691, 1972550),
//   createData(10, 'Japan', 'JP', 126317000, 377973),
//   createData(11, 'France', 'FR', 67022000, 640679),
//   createData(12, 'United Kingdom', 'GB', 67545757, 242495),
//   createData(13, 'Russia', 'RU', 146793744, 17098246),
//   createData(14, 'Nigeria', 'NG', 200962417, 923768),
//   createData(15, 'Brazil', 'BR', 210147125, 8515767),
// ];

// const getRows = (count) => {};

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

export default function StickyHeadTable() {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [tab, setTab] = React.useState(0);
  const [expiriences, setExpiriences] = React.useState([]);
  const history = useHistory();

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onCreateNew = () => {
    history.push({ pathname: '/experiences/new' });
  };

  useEffect(() => {
    const getExperience = async type => {
      const exps = await getExperiences(type);

      setExpiriences(exps.experiences);
    };

    if (tab >= 0) getExperience(tab);
  }, [tab]);

  const rows = getTableRows(expiriences);

  return (
    <Grid container spacing={4}>
      <Grid container item xs={12} justify="flex-end">
        <Grid item>
          <Button variant="contained" color="primary" onClick={onCreateNew}>
            Create new experiment
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          centered>
          <Tab label="RUNNING" />
          <Tab label="DRAFT" />
          <Tab label="PAUSED" />
          <Tab label="ARCHIVED" />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: 500,
                        backgroundColor: 'white',
                      }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}>
                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.render
                                ? column.render(value)
                                : column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                              {/* {column.format && typeof value === 'number' ? column.format(value) : value} */}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}
