import React from 'react';

// Core
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const COLORS = ['green', 'orange', 'blue', 'purple', 'red', 'cyan', 'black'];

export const ModelVsActionLineGraph = ({ data, yAxisKeys, xAxisKey }) => {
  return (
    <ResponsiveContainer height={500} width="100%">
      <ComposedChart data={data} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis orientation="left" stroke="black" />
        <Tooltip labelStyle={{ color: 'black' }} labelFormatter={value => `Score Bucket: ${value}`} cursor={false} />
        <Legend verticalAlign="top" />
        {yAxisKeys.map((key, i) => (
          <Line key={key} dataKey={key} stackId={i} type="monotone" stroke={COLORS[i]} dot={{ stroke: COLORS[i] }} />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
